<!--
 * @Description: 下载简历
 * @Author: huacong
 * @Date: 2021-06-15 11:49:51
 * @LastEditTime: 2021-06-16 18:07:32
 * @LastEditors: huacong
-->
<template>
  <!-- 招聘信息 -->
  <div class="contentBox">
    <section class="bgBox">
      <el-form :model="searchInfo">
        <el-row :gutter="16">
          <el-col :span="5">
            <el-select
              v-model="searchInfo.j_certificate"
              @change="getCerMajor"
              placeholder="选择分类"
              clearable
            >
              <el-option
                v-for="(item, index) in selCerList"
                :key="index"
                :label="item.certificate"
                :value="item.certificate"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="searchInfo.j_major"
              placeholder="选择专业"
              clearable
            >
              <el-option
                v-for="(item, index) in cerChildList"
                :key="index"
                :label="item.certificate"
                :value="item.certificate"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="7">
            <el-cascader
              size="large"
              :options="comcity"
              :props="{ checkStrictly: true }"
              v-model="selectedOptions"
              @change="setUseInfoSocial"
              placeholder="选择省份或城市"
              style="width: 100%;"
              clearable
            >
            </el-cascader>
          </el-col>
          <el-col :span="5">
            <el-select v-model="searchInfo.status" placeholder="简历状态">
              <el-option label="合同快到期" value="合同快到期"></el-option>
              <el-option label="已考过未领取" value="已考过未领取"></el-option>
              <el-option label="已退休" value="已退休"></el-option>
              <el-option label="应聘中" value="应聘中"></el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-button
              type="primary"
              v-prevent-repeat-click
              @click="searchPost"
              class="radius-25"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </section>
    <section>
      <!-- 显示中的信息 -->
      <el-table :data="tableInfo1" tooltip-effect="dark" class="noHeader">
        <el-table-column width="340">
          <template slot-scope="scope">
            <div>
              <p class="fontWeight">
                <span>
                  {{ scope.row.u_name }}
                </span>
              </p>
              <p>
                {{ scope.row.status }} | {{ scope.row.certificate }}-{{
                  scope.row.major
                }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div>
              <span
                >{{ scope.row.week_province }}-{{ scope.row.week_city }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div>
              <span v-if="scope.row.week_salary > 0">
                年薪：{{ scope.row.week_salary }}W
              </span>
              <span v-if="scope.row.week_salary == 0"> 年薪：面议 </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time"> </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="btnBox1 text-center">
              <el-button
                type="text"
                v-prevent-repeat-click
                @click="goDown(scope.row)"
              >
                去下载
              </el-button>
            </div>
          </template>
        </el-table-column>
        <div slot="empty" class="empty">
          <div>
            <img :src="noDate" />
          </div>
          <span>暂无数据</span>
        </div>
      </el-table>
      <div class="pageBox">
        <el-pagination
          background
          @size-change="sizeChange1"
          @current-change="currentChange1"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal"
        >
        </el-pagination>
      </div>
    </section>
    <div style="height:800px;" v-if="markHeight"></div>
    <resumeInfoPage
      ref="resumeBox"
      @markHeightShow="setmarkHeight($event)"
      :resume="this.resumetext"
      v-show="markHeight"
    ></resumeInfoPage>
  </div>
</template>
<script>
import { provinceAndCityData, CodeToText } from "element-china-area-data";
import resumeInfoPage from "@/view/comPage/resume/resumeInfo";
export default {
  components: {
    resumeInfoPage,
  },
  data() {
    return {
      noDate: require("@/assets/img/noDate.png"),
      searchInfo: {},
      selJobList: [],
      selCerList: [],
      cerChildList: [],
      comcity: provinceAndCityData,
      selectedOptions: [],
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      tableInfo1: [],
      resumetext: [],
      markHeight: false,
      downNum: 0,
    };
  },
  computed: {
    userInfo() {
      let k = localStorage.getItem("userInfo");
      console.log(k);
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    // 证书下拉
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        this.selCerList = res.data.resultData;
      });
    },
    // 获取证书专业
    getCerMajor(e) {
      this.selCerList.forEach((element) => {
        if (element.certificate == e) {
          this.cerChildList = element.child;
          this.$set(this.searchInfo, "j_major", "");
        }
      });
    },
    // 设置省市
    setUseInfoSocial() {
      this.searchInfo.province = CodeToText[this.selectedOptions[0]];
      this.searchInfo.city = CodeToText[this.selectedOptions[1]];
    },
    getTableData1() {
      this.searchInfo.page = this.currentPage;
      this.searchInfo.limit = this.pagesize;
      this.searchInfo.is_download = 2;
      this.$http
        .post("company/resume/receiveresume", this.searchInfo)
        .then((res) => {
          this.tableInfo1 = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.list.length);
        });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getTableData1(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getTableData1(e, this.pagesize);
    },
    searchPost() {
      this.currentPage = 1;
      this.getTableData1();
    },
    goDown(item) {
      // if (this.downNum <= 0) {
      //   this.$message.warning("您的下载次数已用完，你可以前往会员中心充值！");
      //   return;
      // }
      this.$http
        .post("talent/index/baseinfo", { uid: item.uid })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.markHeight = true;
            this.resumetext = res.data.resultData;
            this.$refs.resumeBox.uploadPdf();
          }
        });
    },
    setmarkHeight(params) {
      this.markHeight = params;
    },
    // 会员信息
    getVipInfo() {
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          this.downNum = res.data.resultData.u_surplus_down;
        });
    },
  },
  mounted() {
    this.getCerList();
    this.searchPost();
    this.getVipInfo();
  },
};
</script>
<style scoped>
.bgBox {
  margin-bottom: 20px;
}
/deep/ .noHeader .el-table__header-wrapper {
  height: 0;
}
.fontWeight {
  font-size: 17px;
  font-weight: bold;
  color: #000;
}
</style>
