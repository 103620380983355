<!--
 * @Description: 下载简历文件内容-转pdf下载
 * @Author: huacong
 * @Date: 2021-06-15 17:05:45
 * @LastEditTime: 2021-07-06 10:15:14
 * @LastEditors: huacong
-->
<template>
  <div>
    <section>
      <!-- <div @click="uploadPdf()">下载</div> -->
      <div id="boardPdf">
        <el-row>
          <el-col :span="24">
            <div style="height:165px;background: #6A6A6A">
              <!-- 简历信息部分 -->
              <div class="resumeTitle clearfix boxShadow">
                <!-- 头像 -->
                <!-- <div class="block talentAvd">
                  <el-image
                    class="avatar_img"
                    :src="talentInfo.u_portrait_url"
                    alt="暂无头像"
                  ></el-image>
                </div> -->
                <!-- 信息 -->
                <div class="talentInfo">
                  <div class="baseInfo">
                    <span class="name">{{ talentInfo.u_name }}</span>
                    <span class="telbtn">
                      <img :src="phoneIcon" alt="" />
                      <span>{{ talentInfo.u_phone }}</span>
                    </span>
                  </div>
                  <div class="baseInfo">
                    <span v-if="userInfo.u_sex == 1">性别：男</span>
                    <span v-if="userInfo.u_sex == 2">性别：女</span>
                    <span>生日：{{ userInfo.u_birth }}</span>
                    <span
                      >地区：{{ talentResume.week_province }}-{{
                        talentResume.week_city
                      }}</span
                    >
                    <span v-if="talentInfo.u_title != ''">
                      {{ talentInfo.u_title }}
                    </span>
                  </div>
                </div>
                <!-- 电话-分享 -->
                <!-- <div class="talentTel">
                  <div>
                    
                  </div>
                </div> -->
              </div>
            </div>
            <!-- 简历信息 -->
            <div class="resumeInfo resumeInfoList">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span style="font-size:17px;font-weight:bold;">求职描述</span>
                </div>
                <div class="resume-item">
                  <el-form label-width="100px">
                    <el-form-item label="持有证书情况:" style="margin-bottom:0">
                      <p v-for="item in cerInfo" :key="item.id">
                        {{ item.certificate }}-{{ item.major
                        }}<span v-if="item.level != ''">-{{ item.level }}</span
                        >-{{ item.register }}
                      </p>
                    </el-form-item>
                    <el-form-item label="期望城市:" style="margin-bottom:0">
                      <p>
                        {{ talentResume.week_province }}-{{
                          talentResume.week_city
                        }}
                      </p>
                    </el-form-item>
                    <el-form-item label="求职描述:" style="margin-bottom:0">
                      <p style="line-height: 1.5;margin-top: 10px;">
                        {{ talentResume.job_description }}
                      </p>
                    </el-form-item>
                  </el-form>
                </div>
                <!-- 工作经历 -->
                <div class="el-card__header" style="margin-bottom: 0px">
                  <div class="clearfix">
                    <span style="font-size:17px;font-weight:bold;"
                      >工作经历</span
                    >
                  </div>
                </div>
                <div class="resume-item">
                  <div
                    class="clearfix workInfoBox"
                    v-for="(item, index) in talentResume.work_experience"
                    :key="index"
                  >
                    <div class="infoLeft">
                      <p>
                        {{ item.com_name }}
                        ({{ item.work_time.toString().replace(",", "至") }})
                      </p>
                      <p>
                        <span>工作类型:</span>
                        {{ item.titleType.toString().replace(",", "-") }}
                      </p>
                      <p>
                        <span>工作地点:</span>
                        {{ item.city }}
                      </p>
                      <p>
                        <span>工作内容:</span>
                        {{ item.info }}
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 教育经历 -->
                <div class="el-card__header" style="margin-bottom: 0px">
                  <div class="clearfix">
                    <span style="font-size:17px;font-weight:bold;"
                      >教育经历</span
                    >
                  </div>
                </div>
                <div class="resume-item">
                  <div
                    class="clearfix workInfoBox"
                    v-for="(item, index) in talentResume.educational_experience"
                    :key="index"
                  >
                    <div class="infoLeft">
                      <p>
                        {{ item.school }}
                        ({{ item.edu_time.toString().replace(",", " 至 ") }})
                      </p>
                      <p>
                        <span>
                          {{ item.edu }}
                        </span>
                        <span>
                          {{ item.major }}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- 项目名称 -->
                <div class="el-card__header" style="margin-bottom: 0px">
                  <div class="clearfix">
                    <span style="font-size:17px;font-weight:bold;"
                      >项目名称</span
                    >
                  </div>
                </div>
                <div class="resume-item">
                  <div
                    class="clearfix workInfoBox"
                    v-for="(item, index) in talentResume.project_experience"
                    :key="index"
                  >
                    <div class="infoLeft">
                      <p>
                        {{ item.name }}
                        ({{
                          item.project_time.toString().replace(",", " 至 ")
                        }})
                      </p>
                      <p>
                        <span>项目职责:</span>
                        {{ item.xmzz }}
                      </p>
                      <p>
                        <span>工作内容:</span>
                        {{ item.gznr }}
                      </p>
                    </div>
                  </div>
                </div>
              </el-card>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>
<script>
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import $ from "jquery";
export default {
  name: "resumeInfo",
  data() {
    return {
      fileName: "简历",
      // userInfo: {},
      // talentInfo: {},
      // talentResume: {},
      phoneIcon: require("@/assets/icon/phone.png"),
    };
  },
  computed: {
    userInfo() {
      if (this.resume.length == 0) {
        return {};
      }
      return this.resume.base[0];
    },
    talentInfo() {
      if (this.resume.length == 0) {
        return {};
      }
      return this.resume.u_info;
    },
    talentResume() {
      if (this.resume.length == 0) {
        return {};
      }
      return this.resume.resume[0];
    },
    cerInfo() {
      if (this.resume.length == 0) {
        return {};
      }
      return this.resume.cerInfo;
    },
  },
  props: ["resume"],
  methods: {
    export1() {
      this.getPdf("boardPdf", this.fileName);
    },
    downPdf() {
      var title = this.fileName;
      var that = this;
      html2Canvas(document.querySelector("#boardPdf"), {
        allowTaint: true,
      }).then(function(canvas) {
        console.log(canvas.width);
        console.log(canvas.height);
        let contentWidth = canvas.width;
        let contentHeight = canvas.height;
        let pageHeight = (contentWidth / 592.28) * 841.89;
        let leftHeight = contentHeight;
        let position = 0;
        let imgWidth = 595.28;
        let imgHeight = (592.28 / contentWidth) * contentHeight;
        let pageData = canvas.toDataURL("image/jpeg", 1.0);
        let PDF = new JsPDF("", "pt", "a4");
        if (leftHeight < pageHeight) {
          PDF.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          while (leftHeight > 0) {
            PDF.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 842;
            if (leftHeight > 0) {
              PDF.addPage();
            }
          }
        }
        PDF.save(title + ".pdf");
        that.dianpingShow = true;
      });
    },
    isSplit(nodes, index, pageHeight) {
      // 计算当前这块dom是否跨越了a4大小，以此分割
      if (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      ) {
        return true;
      }
      return false;
    },
    uploadPdf() {
      // console.log($);
      $("#boardPdf").css("opacity", 1);
      // let ST = document.documentElement.scrollTop || document.body.scrollTop;
      // let SL = document.documentElement.scrollLeft || document.body.scrollLeft;
      // document.documentElement.scrollTop = 0;
      // document.documentElement.scrollLeft = 0;
      // document.body.scrollTop = 0;
      // document.body.scrollLeft = 0;
      //获取滚动条的位置并赋值为0，因为是el-dialog弹框，并且内容较多出现了纵向的滚动条,截图出来的效果只能截取到视图窗口显示的部分,超出窗口部分则无法生成。所以先将滚动条置顶
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;
      let imageWrapper = document.querySelector("#boardPdf"); // 获取DOM
      let pageHeight = (imageWrapper.scrollWidth / A4_WIDTH) * A4_HEIGHT;
      let lableListID = imageWrapper.querySelectorAll("div");
      // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
      for (let i = 0; i < lableListID.length; i++) {
        let multiple = Math.ceil(
          (lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight
        );
        if (this.isSplit(lableListID, i, multiple * pageHeight)) {
          let divParent = lableListID[i].parentNode; // 获取该div的父节点
          let newNode = document.createElement("div");
          newNode.className = "emptyDiv";
          newNode.style.background = "#ffffff";
          let _H =
            multiple * pageHeight -
            (lableListID[i].offsetTop + lableListID[i].offsetHeight);
          //留白
          newNode.style.height = _H + 20 + "px";
          newNode.style.width = "100%";
          let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
          // 判断兄弟节点是否存在
          if (next) {
            // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
            divParent.insertBefore(newNode, next);
          } else {
            // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
            divParent.appendChild(newNode);
          }
        }
      }
      //接下来开始截图
      this.$nextTick(() => {
        // nexttick可以保证要截图的部分全部执行完毕，具体用法自行百度...
        html2Canvas(imageWrapper, {
          allowTaint: true,
          x: imageWrapper.getBoundingClientRect().left + 13, // 绘制的dom元素相对于视口的位置
          y: imageWrapper.getBoundingClientRect().top,
          width: imageWrapper.offsetWidth - 15, // 因为多出的需要剪裁掉，
          height: imageWrapper.offsetHeight,
          backgroundColor: "#FFF", //一定要设置北京颜色，否则有的浏览器就会变花~，比如Edge
          useCORS: true,
          scale: 3, // 图片模糊
          dpi: 350, //z
        }).then((canvas) => {
          let pdf = new JsPDF("p", "mm", "a4"); //A4纸，纵向
          let ctx = canvas.getContext("2d"),
            a4w = 190,
            a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
            imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
            renderedHeight = 0;

          while (renderedHeight < canvas.height) {
            let page = document.createElement("canvas");
            page.width = canvas.width;
            page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
            //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
            page
              .getContext("2d")
              .putImageData(
                ctx.getImageData(
                  0,
                  renderedHeight,
                  canvas.width,
                  Math.min(imgHeight, canvas.height - renderedHeight)
                ),
                0,
                0
              );
            pdf.addImage(
              page.toDataURL("image/jpeg", 0.2),
              "JPEG",
              10,
              10,
              a4w,
              Math.min(a4h, (a4w * page.height) / page.width)
            ); //添加图像到页面，保留10mm边距
            renderedHeight += imgHeight;
            if (renderedHeight < canvas.height) pdf.addPage(); //如果后面还有内容，添加一个空页
          }
          pdf.save("测试.pdf");
          $("#boardPdf").css("opacity", 0);
          console.log("隐藏");
          this.$emit("markHeightShow", false);
          console.log("高度消失");
          // let uploadPdf = pdf.output("datauristring"); //转成Base64
          // let arr = uploadPdf.split(",");
          // let mime = arr[0].match(/:(.*?);/)[1];
          // let suffix = mime.split("/")[1];
          // let bstr = window.atob(arr[1]); //自行百度新大陆
          // let n = bstr.length;
          // let u8arr = new Uint8Array(n);
          // let filename = "temp_img";
          // while (n--) {
          //   u8arr[n] = bstr.charCodeAt(n);
          // }
          // let file = new File([u8arr], `${filename}.${suffix}`, { type: mime });
          // let formdata = new FormData();
          // formdata.append("uploadfile", file);
          // let params = { project: this.pid, courseid: this.id };
          // this.$axios({
          //   url: `vueindex/UploadWord/uploadpdf`,
          //   method: "post",
          //   headers: { "Content-Type": "multipart/form-data " },
          //   data: formdata,
          //   params: params,
          // }).then((res) => {
          //   if (res.data.code == 0) {
          //     //上传成功，刷新页面，打完收工
          //   }
          // });
        });
      });
    },
  },
};
</script>
<style scoped>
#boardPdf {
  opacity: 0;
}
.resumeTitle {
  border-bottom: 1px solid #e8e8e8;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-radius: 0;
}
.talentIndex {
  padding-top: 24px;
}
.demonstration {
  margin-top: 10px;
}
.talentAvd {
  display: block;
  float: left;
}
.avatar_img {
  border-radius: 50%;
  border: 1px solid #ddd;
}
.talentInfo {
  display: block;
  float: left;
  width: calc(100% - 470px);
  padding: 20px 10px;
}
.talentInfo .name {
  font-size: 17px;
  font-weight: bold;
  padding: 0px 0px;
  display: inline-block;
}
.baseInfo {
  margin-top: 14px;
}
.talentInfo .baseInfo span {
  display: inline-block;
  padding: 0px 16px;
}
.talentInfo .baseInfo span:first-child {
  padding-left: 0;
}
.contact {
  margin-top: 14px;
}
.contact > span {
  padding: 0px 0;
  display: inline-block;
}
.contact span img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.contact > span {
  padding-right: 20px;
}
.talentTel {
  float: right;
  padding: 20px;
  text-align: right;
}
.telbtn {
  display: inline-block;
  padding: 3px 10px;
  background: #2573f1;
  border-radius: 20px;
  color: #fff;
}
.telbtn img {
  position: relative;
  top: 2px;
  padding-right: 6px;
}
.talentTel p {
  margin-top: 14px;
}
.shellBox img {
  padding: 0px 4px;
}
/* 简历信息 */
.editPencil {
  float: right;
  padding: 3px;
  font-size: 16px;
  color: #2573f1;
  cursor: pointer;
}
.resumeInfo .resume-item {
  padding: 20px;
  min-height: 50px;
}
/* 工作经历 */
.workInfoBox {
  padding: 6px 0px;
}
.workInfoBox p {
  padding: 5px 0;
}
.infoLeft span {
  color: #999;
}
</style>
